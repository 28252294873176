import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

//? **** Firebase ************************************************************
// import { getAnalytics } from 'firebase/analytics';
import { 
  provideFirebaseApp, 
  // getApp, 
  initializeApp } from '@angular/fire/app'; 
import { provideAuth, getAuth } from '@angular/fire/auth';
// import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideDatabase, getDatabase } from '@angular/fire/database';
// import { provideMessaging, getMessaging } from '@angular/fire/messaging'; 

import LaraLightBlue from '@primeng/themes/lara';

import { environment as env } from './../environments/environment';     

// UI
// import { AngularFireAuthModule } from '@angular/fire/compat/auth'; 
import { LANGUAGE_CODE } from '@angular/fire/compat/auth';

import * as firebaseui from 'firebaseui';
import { FirebaseUIModule, firebase } from 'firebaseui-angular';

 
//? *************************************************************************** 

//* ------- LOCALE -------------------------------------- 
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common'; 
import localeIt from '@angular/common/locales/it';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { appInterceptor  } from './interceptors/app-interceptor.interceptor';
import { providePrimeNG } from 'primeng/config';

export const BROWSER_LANG = navigator.language || 'it-IT'; 
export const BROWSER_LANG_SHORT = navigator.language.split('-')[0] || 'it';  
registerLocaleData(localeIt, BROWSER_LANG_SHORT);  


// import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// export const IT_DATE_FORMATS = {
//   parse: {
//     dateInput: 'DD/MM/YYYY',
//   },
//   display: {
//     dateInput: 'DD/MM/YYYY',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   }
// };
 

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',  
  signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID ,
      // {
      //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      //   requireDisplayName: true
      //   //signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
      // },
      
  ],  
  // tosUrl: 'tos', //TODO: add term of service  url 
  // privacyPolicyUrl: 'privacy', //TODO: add privacy policy url
  
  credentialHelper: firebaseui.auth.CredentialHelper.NONE
};
 
export const appConfig: ApplicationConfig = { 
  providers: [ 
    ConfirmationService,  // confirm dialog PrimeNG
    MessageService,  // toast PrimeNG
    provideHttpClient( withInterceptors([appInterceptor]) ),
    // {provide: USE_AUTH_EMULATOR, useValue: !env.production ? ['localhost', 9099] : undefined},
    provideRouter(routes, withComponentInputBinding()),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
          preset: LaraLightBlue,
          options: {
            darkModeSelector: '.my-app-dark'
          }
      }
    }),
    { provide: LOCALE_ID, useFactory: () => BROWSER_LANG  },
    { provide: LANGUAGE_CODE, useValue: 'fr' },
    // { provide: MAT_DATE_LOCALE, useValue: 'it-IT'} ,
    // { provide: MAT_DATE_FORMATS, useValue: IT_DATE_FORMATS },
    { provide: FIREBASE_OPTIONS, useValue: env.firebase },
    provideFirebaseApp(() => initializeApp(env.firebase)),
    provideAuth(() => getAuth()),  
    provideDatabase(() => getDatabase()),      
    // provideFirestore(() => getFirestore()),
    // provideStorage(() => getStorage())
    importProvidersFrom([  
      AngularFireModule.initializeApp(env.firebase),
      // AngularFireAuthModule,
      FirebaseUIModule.forRoot(firebaseUiAuthConfig)     
    ])
  ]
};

 
