const serverBE = 'https://dev.crm.api.oncharge.it/api/v1';  //? BE DEV 

export const environment = { 

  environment: 'DEV',

  production: false,  
  dev: true,        //! DEV ENV
  uat: false,    

  baseServerURL: `${serverBE}/`,     
  HF_TOKEN : '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', 

  FLOWISE_TOKEN : 'adfBvhellXdzqI_adpIL6YD40a08ox7hlQJQpuur-Wc',

  VOICEFLOW_API_KEY: 'VF.DM.67c6ee15529ca0c9ed929a8b.qG8pmjSm4ab2W6M2', 
  VOICEFLOW_PROJECT_ID: '67c6e5ccb7f5c7f7e0bf8de2', 

  WHATSAPP_VERSION: 'v21.0',
  WHATSAPP_TOKEN: 'EAAQJctGZAl9IBO3LVQOBLZAARPxlZAKpjVO5jbuMsQ9XEJVTRx3ZCbbq4WHaMaJ9O8D2qIjKi7r4QLKA3JuRtaCSayWfqnFwmWWYwXaRiPRl6QgiTsIZCWPfpW8eFtuUw7MxBxWjZBpGZAZAX1LkZAO9FGFSZBZAgUOaHzVhvMxc3PgJptE2lAuaKW6OupoGFuJscmEqwZDZD',
  WHATSAPP_BUSINESS_ID: '471950669345523',
  WHATSAPP_NUMBER_ID: '526409377223805',
 
  firebase: {
    apiKey: 'AIzaSyDHaS_SoRhf9nX3GZIn4eiCx1LWJ-e0WIg',
    databaseURL: 'https://zeus-7e655-default-rtdb.europe-west1.firebasedatabase.app',
    authDomain: 'zeus-7e655.firebaseapp.com',
    projectId: 'zeus-7e655',
    storageBucket: 'zeus-7e655.appspot.com',
    messagingSenderId: '74069116625',
    appId: '1:74069116625:web:3399dd2f51da7ab10167f0',
    measurementId: 'G-WBLKT9E1C4'
  },

  googleMapsApiKey: 'AIzaSyCjkQhKh9IrfCYNnve7vj_XlPGGT9LMa20' ,

  // sentry: {
  //   dsn: 'https://138dab8cb7294cd3e6163b12032a4c9f@o4506734805385216.ingest.sentry.io/4506734808399872'
  // }
};

